import { PaymentMethod, TransactionSource } from "#resources/api/enterprise-generated";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useDebounceValue } from "#resources/hooks";
import { SalesReportProps } from "../types";
import { DEBOUNCE_TIMEOUT } from "./constants";

const useFilters = (props: SalesReportProps) => {
	const { since: _since, until: _until } = props;
	const [rangeDate, setRangeDate] = useState<[Dayjs, Dayjs]>([
		dayjs(_since),
		dayjs(_until),
	]);
	const [since, until] = [rangeDate[0].toDate(), rangeDate[1].toDate()];

	const [_clientNameFilter, setClientNameFilter] = useState<string>("");
	const [clientNameFilter] = useDebounceValue(_clientNameFilter, DEBOUNCE_TIMEOUT);

	const [_employeesFilter, setEmployeesFilter] = useState<string[]>([]);
	const [employeesFilter] = useDebounceValue(_employeesFilter, DEBOUNCE_TIMEOUT);

	const [_paymentMethodsFilter, setPaymentMethodsFilter] = useState<PaymentMethod[]>([]);
	const [paymentMethodsFilter] = useDebounceValue(
		_paymentMethodsFilter,
		DEBOUNCE_TIMEOUT,
	);
	const [_transactionsFilter, setTransactionsFilter] = useState<TransactionSource[]>([]);
	const [transactionsFilter] = useDebounceValue(_transactionsFilter, DEBOUNCE_TIMEOUT);

	const [_productsFilter, setProductsFilter] = useState<string[]>([]);
	const [productsFilter] = useDebounceValue(_productsFilter, DEBOUNCE_TIMEOUT);

	const [_barsFilter, setBarsFilter] = useState<string[]>([]);
	const [barsFilter] = useDebounceValue(_barsFilter, DEBOUNCE_TIMEOUT);

	const [_categoriesFilter, setCategoriesFilter] = useState<string[]>([]);
	const [categoriesFilter] = useDebounceValue(_categoriesFilter, DEBOUNCE_TIMEOUT);

	return {
		rangeDate,
		setRangeDate,
		clientNameFilter,
		setClientNameFilter,
		employeesFilter,
		setEmployeesFilter,
		paymentMethodsFilter,
		setPaymentMethodsFilter,
		transactionsFilter,
		setTransactionsFilter,
		productsFilter,
		setProductsFilter,
		barsFilter,
		setBarsFilter,
		categoriesFilter,
		setCategoriesFilter,
		since,
		until,
	};
};

export default useFilters;
