import * as ReactGA from "react-ga";
import { action, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "#helpers/mobx-utils";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";
import { RootStore } from ".";
import { fetchModel } from "#helpers/fetch-model";
import {
	BackofficeAccount,
	BackofficeAccountBillsReportResponse,
	BackofficeAccountV2,
	BackofficeAddress,
	BackofficeAttachment,
	BackofficeBankAccount,
	BackofficeBaseAccountV2,
	BackofficeBill,
	BackofficeBillDateFilter,
	BackofficeBillHistory,
	BackofficeBillStatusFilter,
	BackofficeBillSupplierTrackerReport,
	BackofficeBillType,
	BackofficeCashFlow,
	BackofficeCategory,
	BackofficeCategoryHistory,
	BackofficeCostCenter,
	BackofficeCostCenterReport,
	BackofficeDayReport,
	BackofficeDeleteProductsResult,
	BackofficeDescriptionReport,
	BackofficeExtract,
	BackofficeGetAccountBillsResponse,
	BackofficeImportedInvoice,
	BackofficeIncomeByCategory,
	BackofficeIncomeStatement,
	BackofficeInputV2,
	BackofficeMultipleStorageTransferProduct,
	BackofficeMultipleStorageTransferProductResult,
	BackofficeNewAccount,
	BackofficeNewBill,
	BackofficeNewBillType,
	BackofficeNewCategory,
	BackofficeNewCostCenter,
	BackofficeNewSupplier,
	BackofficeNewTransfer,
	BackofficeParamDates,
	BackofficePeriodResume,
	BackofficeProductStorageHistoryFilterBy,
	BackofficeResume,
	BackofficeStorage,
	BackofficeStorageHistoryV4,
	BackofficeStorageInventoriesResult,
	BackofficeStorageInventoryAndStorageComparison,
	BackofficeStorageInventoryProduct,
	BackofficeStorageInventoryProductInconsistency,
	BackofficeStorageInventoryStatus,
	BackofficeStorageInventoryWithStorage,
	BackofficeStorageProductsHistoryV3,
	BackofficeStorageTransferType,
	BackofficeSupplier,
	BackofficeTransfer,
	BackofficeTransfersBetweenStoragesResult,
	BackofficeTypeAccountBills,
	BackofficeUpdateStorageInventoryProductsTransferType,
	Bar,
	BaseGetBackofficeTransfersBetweenStoragesFilters,
	CompleteBackofficeStorageInventory,
	ExportBackofficeTransfersBetweenStoragesOrder,
	ExportBackofficeTransfersBetweenStoragesOrderBy,
	FinancialEventClosingConference,
	GetBackofficeCashFlowV2GroupBy,
	GetBackofficeConfig,
	GetBackofficeStorageInventoriesOrder,
	GetBackofficeStorageInventoriesOrderBy,
	GetBackofficeStorageInventoryAndStorageComparisonFilters,
	GetBackofficeStorageInventoryAndStorageComparisonOrder,
	GetBackofficeStorageInventoryAndStorageComparisonOrderBy,
	GetBackofficeStorageInventoryTopInconsistenciesType,
	GetBackofficeTransfersBetweenStoragesFilters,
	GetBackofficeTransfersBetweenStoragesOrder,
	GetBackofficeTransfersBetweenStoragesOrderBy,
	GetStoragesTransfersHistoryByContextFilters,
	Pagination,
	PlaceProduct,
	ProductAbcCurve,
	StoragePositionReport,
	StorageProduct,
	StorageTransferHistoryResult,
	SupplierAbcCurve,
} from "#resources/api/enterprise-generated";
import enterprise from "#resources/api/enterprise-client";
import i18n from "#i18n/index";
import { add, datetime, startOf, subtract } from "#resources/helpers/date-format";

const t = i18n.t;

interface IGetAndDeleteAccountArgs {
	placeId: string;
	accountId: string;
}

interface IGetAccountsArgs {
	placeId: string;
}

interface ICreateAccountArgs {
	placeId: string;
	account: BackofficeBaseAccountV2;
}

interface IUpdateAccountArgs {
	placeId: string;
	accountId: string;
	account: BackofficeBaseAccountV2;
}

export class BackofficeStore {
	public rootStore: RootStore;

	public constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable
	public loading: boolean = false;
	@observable
	public StorageProduct: StorageProduct[] = [];

	@observable
	public bills: BackofficeBill[] = [];

	@observable
	public billTypes: BackofficeBillType[] = [];

	@observable
	public costCenters: BackofficeCostCenter[] = [];

	@observable
	public accounts: BackofficeAccount[] = [];

	@observable
	public addresses: BackofficeAddress[] = [];

	@observable
	public bankAccounts: BackofficeBankAccount[] = [];

	@observable
	public categories: BackofficeCategory[] = [];

	@observable
	public suppliers: BackofficeSupplier[] = [];

	@observable
	public incomeStatement: BackofficeIncomeStatement | null = null;

	@observable
	public incomeByCategory: BackofficeIncomeByCategory[] | null = null;

	@observable
	public billHistory: BackofficeBillHistory | null = null;

	@observable
	public extract: BackofficeExtract | null = null;

	@observable
	public cashflow: BackofficeCashFlow | null = null;

	@observable
	public description: BackofficeDescriptionReport | null = null;

	@observable
	public dayReport: BackofficeDayReport | null = null;

	@observable
	public storagePositions: StoragePositionReport = { products: [], totalCost: "0" };

	@observable
	public storages: BackofficeStorage[] | null = null;

	@observable
	public allStorages: BackofficeStorage[] = [];

	@observable
	public typeReport: BackofficeDescriptionReport | null = null;

	@observable
	public perPagelimit: number | undefined = undefined;

	@observable
	public pageNumber: number | undefined = undefined;

	@observable
	public categoryReport: BackofficeDescriptionReport | null = null;

	@observable
	public costCenterReport: BackofficeCostCenterReport | null = null;

	@observable
	public paidOrReceivedReport: BackofficeBillSupplierTrackerReport | null = null;

	@observable
	public categoryHistory: BackofficeCategoryHistory | null = null;

	@observable
	public bars: Bar[] | null = null;

	@observable
	public placeProducts: PlaceProduct[] = [];

	@observable
	public backofficeConfig: GetBackofficeConfig | undefined = undefined;

	public backofficeResume: BackofficeResume | null = null;

	@observable
	public backofficeResumeInPeriod: BackofficePeriodResume | null = null;

	@observable
	public backofficeOverridenAccount: BackofficeAccount | null = null;

	@observable
	public productHistory?: BackofficeStorageHistoryV4;

	@observable
	public billsPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public editBillPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public deleteBillPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public billTypesPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public costCentersPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public createCostCentersPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public editCostCentersPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public deleteCostCentersPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public accountsPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public addressesPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public bankAccountsPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public categoriesPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public suppliersPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public incomeStatementPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public importBackofficeBillsPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public billHistoryPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public extractPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public cashflowPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public expensesPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public barsPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public editBarPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public receiptsPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public dayReportPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public storagesPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public typeReportPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public categoryReportPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public categoryHistoryPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public costCenterPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public addBarPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public placeProductsPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public paidOrReceivedPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public backofficeResumePromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public backofficeOverridenAccountPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public reportProductsPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public organizationStorePromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public until = datetime().compose(startOf("day"), add(1, "month"));

	@observable
	public since = datetime().compose(startOf("day"), subtract(1, "month"));

	@observable
	public statusFilter: BackofficeBillStatusFilter = "Any";

	@observable
	public dateFilter: BackofficeBillDateFilter = "ByPerform";

	@action
	public clear = () => {
		this.billsPromise = null;
		this.backofficeImportedInvoices.reset();
		this.getProductsAtStorage.reset();
		this.fetchStorages.reset();
		this.getBackofficeStorageInventories.reset();
		this.createBackofficeStorageInventory.reset();
		this.getBackofficeStorageInventory.reset();
		this.updateBackofficeStorageInventoryProducts.reset();
		this.updateBackofficeStorageInventoryStatus.reset();
		this.getBackofficeStorageInventoryAndStorageComparison.reset();
		this.exportBackofficeStorageInventoryAndStorageComparison.reset();
		this.deleteBackofficeStorageInventory.reset();
		this.getAccountV2.reset();
		this.getAccountsV2.reset();
		this.createAccountV2.reset();
		this.updateAccountV2.reset();
		this.deleteAccountV2.reset();
		this.fetchStoragePosition.reset();
		this.exportBackofficeTransfersBetweenStorages.reset();
		this.getBackofficeTransfersBetweenStorages.reset();
	};

	public getAccountV2 = new fetchModel<IGetAndDeleteAccountArgs, BackofficeAccountV2>({
		fnPromise: args => enterprise.getBackofficeAccountV2(args),
		onError: err => showErrorNotification(err.message),
	});

	public getAccountsV2 = new fetchModel<IGetAccountsArgs, BackofficeAccountV2[]>({
		fnPromise: args => enterprise.getBackofficeAccountsV2(args),
		onError: err => showErrorNotification(err.message),
	});

	public createAccountV2 = new fetchModel<ICreateAccountArgs, void>({
		fnPromise: args => enterprise.createBackofficeAccountV2(args),
		onError: err => showErrorNotification(err.message),
	});

	public updateAccountV2 = new fetchModel<IUpdateAccountArgs, void>({
		fnPromise: args => enterprise.updateBackofficeAccount(args),
		onError: err => showErrorNotification(err.message),
	});

	public deleteAccountV2 = new fetchModel<IGetAndDeleteAccountArgs, void>({
		fnPromise: args => enterprise.deleteBackofficeAccountV2(args),
		onError: err => {
			if (err.message.type === "Generic") showErrorNotification(err.message);
			return;
		},
	});

	@action
	public fetchBills = (
		placeId: string,
		statusFilter?: BackofficeBillStatusFilter,
		since?: Date,
		until?: Date,
	) => {
		this.until = until || this.until;
		this.since = since || this.since;
		this.statusFilter = statusFilter || this.statusFilter;

		this.billsPromise = fromPromise(
			enterprise
				.getBackofficeBills({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: this.statusFilter,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.bills = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@observable
	public transfers: BackofficeTransfer[] = [];

	@action
	public fetchTransfers = async (placeId: string, since?: Date, until?: Date) => {
		this.until = until || this.until;
		this.since = since || this.since;

		try {
			this.transfers = await enterprise.getBackofficeTransfers({
				placeId,
				since: this.since,
				until: this.until,
			});
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public createTransfer = async (placeId: string, transfer: BackofficeNewTransfer) => {
		try {
			const theTransfer = await enterprise.doBackofficeTransfer({ placeId, transfer });
			showSuccessNotification(t("store:backofficeStore.createTransfer"));
			this.transfers.unshift(theTransfer);
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public editTransfer = async (
		placeId: string,
		transferId: string,
		transfer: BackofficeNewTransfer,
	) => {
		try {
			const theTransfer = await enterprise.editBackofficeTransfer({
				placeId,
				id: transferId,
				transfer,
			});
			showSuccessNotification(t("store:backofficeStore.editTransfer"));
			const findIndex = this.transfers.findIndex(transf => transf.id === transferId);
			this.transfers[findIndex] = theTransfer;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public deleteTransfer = async (placeId: string, transferId: string) => {
		try {
			await enterprise.deleteBackofficeTransfer({ placeId, id: transferId });
			showSuccessNotification(t("store:backofficeStore.deleteTransfer"));
			this.transfers = this.transfers.filter(transfer => transfer.id !== transferId);
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@observable
	public reportProducts?: BackofficeStorageProductsHistoryV3[] = undefined;

	@action
	public getBackofficeProductsStorageHistory = async (
		placeId: string | null,
		storageId: string,
		productIds: string[],
		since: Date,
		until: Date,
		filterBy: BackofficeProductStorageHistoryFilterBy,
	) => {
		this.reportProducts = undefined;
		this.reportProductsPromise = fromPromise(
			enterprise
				.getBackofficeProductsStorageHistoryV3({
					placeId: placeId!,
					storageId,
					productIds,
					filterBy,
					since,
					until,
					itemsPerPage: null,
					page: null,
				})
				.then(res => {
					this.reportProducts = res;
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public cleanBackofficeProductsStorageHistory = () => {
		this.reportProducts = undefined;
	};

	@action
	public editBill = (
		placeId: string,
		id: string,
		bill: BackofficeNewBill,
		attachment: BackofficeAttachment | null,
	) => {
		this.editBillPromise = fromPromise(
			enterprise
				.editBackofficeBill({ placeId, id, bill })
				.then(() => {
					if (attachment) {
						enterprise
							.editBackofficeBillAttachment({ placeId, id, attachment })
							.then(() => {
								this.fetchBills(placeId);
								showSuccessNotification(t("store:backofficeStore.editBill"));
							})
							.catch((err: any) => {
								console.error(err);
								if (err instanceof Error) {
									showErrorNotification(err.message);
								}
							});
					} else {
						this.fetchBills(placeId);
						showSuccessNotification(t("store:backofficeStore.editBill"));
					}
				})
				.catch((err: any) => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public editDateFilter = () => {
		if (this.dateFilter === "ByDue") {
			this.dateFilter = "ByPerform";
		} else {
			this.dateFilter = "ByDue";
		}
	};

	@action
	public deleteBill = (placeId: string, id: string) => {
		this.deleteBillPromise = fromPromise(
			enterprise
				.deleteBackofficeBill({ placeId, id })
				.then(_ => {
					this.fetchBills(placeId);
					showSuccessNotification(t("store:backofficeStore.deleteBill"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public fetchBillTypes = (placeId: string) => {
		this.billTypesPromise = fromPromise(
			enterprise
				.getBackofficeBillTypes({ placeId })
				.then(v => {
					this.billTypes = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public importBackofficeBills = (placeId: string, file: Buffer) => {
		this.importBackofficeBillsPromise = fromPromise(
			enterprise
				.importBackofficeBills({ placeId, file })
				.then(_ => {
					this.fetchBills(placeId);
					showSuccessNotification(t("store:backofficeStore.importBackofficeBills"));
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public createBillTypes = (placeId: string, billType: BackofficeNewBillType) => {
		this.billTypesPromise = fromPromise(
			enterprise
				.createBackofficeBillType({ placeId, billType })
				.then(_ => {
					this.fetchBillTypes(placeId);
					showSuccessNotification(t("store:backofficeStore.createBillTypes"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public createCostCenters = (placeId: string, costCenter: BackofficeNewCostCenter) => {
		this.createCostCentersPromise = fromPromise(
			enterprise
				.createBackofficeCostCenter({ placeId, costCenter })
				.then(_ => {
					this.fetchCostCenters(placeId);
					showSuccessNotification(t("store:backofficeStore.createCostCenters"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public deleteCostCenter = (placeId: string, id: string) => {
		this.deleteCostCentersPromise = fromPromise(
			enterprise
				.deleteBackofficeCostCenter({ placeId, id })
				.then(_ => {
					this.fetchCostCenters(placeId);
					showSuccessNotification(t("store:backofficeStore.deleteCostCenter"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public editCostCenter = (
		placeId: string,
		id: string,
		costCenter: BackofficeCostCenter,
	) => {
		this.editCostCentersPromise = fromPromise(
			enterprise
				.editBackofficeCostCenter({ placeId, id, costCenter })
				.then(_ => {
					this.fetchCostCenters(placeId);
					showSuccessNotification(t("store:backofficeStore.editCostCenter"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public getBackofficeOverridenAccount = async (placeId: string) => {
		this.backofficeOverridenAccountPromise = fromPromise(
			enterprise
				.getOverridenPlaceAccount({ placeId })
				.then(res => {
					this.backofficeOverridenAccount = res;
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public createBackofficeOverridenAccount = async (
		placeId: string,
		accountId: string | null,
	) => {
		try {
			await enterprise.overridePlaceAccountId({ placeId, accountId });
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public fetchAccounts = (placeId: string) => {
		this.accountsPromise = fromPromise(
			enterprise
				.getBackofficeAccounts({ placeId })
				.then(v => {
					this.fetchBillTypes(placeId);
					this.accounts = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public getBackofficeConfig = async (placeId: string) => {
		try {
			this.backofficeConfig = await enterprise.getBackofficeConfig({ placeId });
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public createAccount = async (placeId: string, account: BackofficeAccount) => {
		try {
			await enterprise.createBackofficeAccount({ placeId, account });
			this.fetchAccounts(placeId);
		} catch (err) {
			console.error(err);
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public deleteAccount = (placeId: string, id: string) => {
		this.accountsPromise = fromPromise(
			enterprise
				.deleteBackofficeAccount({ placeId, id })
				.then(_ => {
					this.fetchAccounts(placeId);
					showSuccessNotification(t("store:backofficeStore.deleteAccount"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public editAccount = (placeId: string, id: string, account: BackofficeNewAccount) => {
		this.accountsPromise = fromPromise(
			enterprise
				.editBackofficeAccount({ placeId, id, account })
				.then(_ => {
					this.fetchAccounts(placeId);
					showSuccessNotification(t("store:backofficeStore.editAccount"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public editBillTypes = (
		placeId: string,
		id: string,
		billType: BackofficeNewBillType,
	) => {
		this.billTypesPromise = fromPromise(
			enterprise
				.editBackofficeBillType({ placeId, id, billType })
				.then(_ => {
					this.fetchBillTypes(placeId);
					showSuccessNotification(t("store:backofficeStore.editBillTypes"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public deleteBillTypes = (placeId: string, id: string) => {
		this.billTypesPromise = fromPromise(
			enterprise
				.deleteBackofficeBillType({ placeId, id })
				.then(_ => {
					this.fetchBillTypes(placeId);
					showSuccessNotification(t("store:backofficeStore.deleteBillTypes"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public fetchCostCenters = (placeId: string) => {
		this.costCentersPromise = fromPromise(
			enterprise
				.getBackofficeCostCenters({ placeId })
				.then(v => {
					this.costCenters = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public fetchCategories = (placeId: string) => {
		this.categoriesPromise = fromPromise(
			enterprise
				.getBackofficeCategories({ placeId })
				.then(v => {
					this.categories = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public fetchSuppliers = (
		placeId: string,
		perPagelimit?: number,
		pageNumber?: number,
	) => {
		this.perPagelimit = perPagelimit !== undefined ? perPagelimit : this.perPagelimit;
		this.pageNumber = pageNumber !== undefined ? pageNumber : this.pageNumber;
		this.categoriesPromise = fromPromise(
			enterprise
				.getBackofficeSuppliers({ placeId })
				.then(v => {
					this.suppliers = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public createNewBill = async (
		placeId: string,
		newBill: BackofficeNewBill,
		attachment: BackofficeAttachment | null,
	) => enterprise.createBackofficeBill({ placeId, bill: newBill, attachment });

	public exportBackofficeBills = async (placeId: string) => {
		try {
			const url = await enterprise.exportBackofficeBills({
				placeId,
				exportBillArgs: {
					performDate: {
						since: this.since,
						until: this.until,
					},
					dueDate: {
						since: this.since,
						until: this.until,
					},
					issueDate: {
						since: this.since,
						until: this.until,
					},
					payDate: {
						since: this.since,
						until: this.until,
					},
					type: this.statusFilter,
					status: "Any",
					bankAccountIds: [],
					supplierIds: [],
					costCenterIds: [],
					billPlanCategoryIds: [],
					numBill: null,
					description: null,
					observation: null,
					totalValue: null,
				},
			});
			window.location.href = url;
		} catch (err) {
			console.error(err);
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public fetchIncomeStatement = (placeId: string) => {
		this.incomeStatementPromise = fromPromise(
			enterprise
				.getBackofficeIncomeByCategory({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: this.statusFilter,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.incomeByCategory = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public createCategorie = (placeId: string, category: BackofficeNewCategory) => {
		this.categoriesPromise = fromPromise(
			enterprise
				.createBackofficeCategory({ placeId, category })
				.then(_ => {
					this.fetchCategories(placeId);
					showSuccessNotification(t("store:backofficeStore.createCategorie"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public editCategorie = (
		placeId: string,
		id: string,
		category: BackofficeNewCategory,
	) => {
		this.categoriesPromise = fromPromise(
			enterprise
				.editBackofficeCategory({ placeId, id, category })
				.then(_ => {
					this.fetchCategories(placeId);
					showSuccessNotification(t("store:backofficeStore.editCategorie"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public deleteCategorie = (placeId: string, id: string) => {
		this.categoriesPromise = fromPromise(
			enterprise
				.deleteBackofficeCategory({ placeId, id })
				.then(_ => {
					this.fetchCategories(placeId);
					showSuccessNotification(t("store:backofficeStore.deleteCategorie"));
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public fetchBillHistory = (placeId: string) => {
		this.billHistoryPromise = fromPromise(
			enterprise
				.getBackofficeBillHistory({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: this.statusFilter,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.billHistory = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public fetchExtract = (placeId: string) => {
		this.extractPromise = fromPromise(
			enterprise
				.getBackofficeExtract({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: this.statusFilter,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.extract = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public fetchCashFlow = (placeId: string) => {
		this.cashflowPromise = fromPromise(
			enterprise
				.getBackofficeCashFlow({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: this.statusFilter,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.cashflow = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public fetchDescriptionReport = (placeId: string, type: BackofficeBillStatusFilter) => {
		this.expensesPromise = fromPromise(
			enterprise
				.getBackofficeDescriptionReport({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: type,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.description = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public fetchDayReport = (placeId: string, type: BackofficeBillStatusFilter) => {
		this.dayReportPromise = fromPromise(
			enterprise
				.getBackofficeDayReport({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: type,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.dayReport = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	public fetchReceiptsReport = (placeId: string) => {
		this.receiptsPromise = fromPromise(
			enterprise
				.getBackofficeDescriptionReport({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: "ToReceive",
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.description = v;
				})
				.catch(err => {
					console.error(err);
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	public fetchStoragesProductsReport = new fetchModel<
		{
			placeId: string;
			productIds: string[];
			since: Date;
			until: Date;
			storageIds: string[];
			filterBy: BackofficeProductStorageHistoryFilterBy;
		},
		BackofficeStorageProductsHistoryV3[]
	>({
		fnPromise: args => enterprise.getBackofficeProductsStoragesHistoryV3(args),
		onSuccess: rtn => {
			this.reportProducts = rtn;
		},
		onError: err => showErrorNotification(err.message),
	});

	public fetchStoragePosition = new fetchModel<
		{
			placeId: string;
			storageId: string;
			date: Date;
		},
		StoragePositionReport
	>({
		fnPromise: args => enterprise.getBackofficeStoragePositionReport(args),
		onSuccess: args => {
			this.storagePositions = args;
		},
		onError: err => showErrorNotification(err.message),
	});

	public fetchStorages = new fetchModel<{ placeId: string }, BackofficeStorage[]>({
		fnPromise: args => enterprise.getBackofficeStorages(args),
		onSuccess: args => {
			this.storages = args;
		},
		onError: err => showErrorNotification(err.message),
	});

	public fetchAllStoragesByOrganization = new fetchModel<
		{ orgId: string },
		BackofficeStorage[]
	>({
		fnPromise: ({ orgId }) =>
			enterprise.getAllBackofficeStorages({ organizationId: orgId }),
		onError: err => showErrorNotification(err.message),
	});

	public hasCentralStorage = new fetchModel<{}, boolean>({
		fnPromise: () =>
			enterprise.hasCentralStorage({
				organizationId: this.rootStore.authStore.currentEmployee?.organization?.id!,
			}),
		onError: err => showErrorNotification(err.message),
	});

	public addStorage = new fetchModel<
		{ placeId: string | null; orgId: string | null; name: string },
		BackofficeStorage
	>({
		fnPromise: ({ placeId, orgId, name }) =>
			enterprise.createBackofficeStorage({
				placeId,
				organizationId: orgId,
				storage: { name },
			}),
		onSuccess: () => showSuccessNotification(t("store:backofficeStore.addStorage")),
		onError: err => showErrorNotification(err.message),
	});

	public updateStorage = new fetchModel<
		{ placeId: string | null; orgId: string | null; storageId: string; name: string },
		void
	>({
		fnPromise: ({ name, placeId, orgId, storageId }) =>
			enterprise.editBackofficeStorage({
				placeId,
				organizationId: orgId,
				id: storageId,
				storage: { name },
			}),
		onSuccess: () => showSuccessNotification(t("store:backofficeStore.updateStorage")),
		onError: err => showErrorNotification(err.message),
	});

	public deleteStorage = new fetchModel<
		{ placeId: string | null; storeId: string },
		void
	>({
		fnPromise: ({ placeId, storeId }) =>
			enterprise.deleteBackofficeStorage({ id: storeId, placeId }),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public fetchPlaceProducts = async (placeId: string) => {
		this.placeProductsPromise = fromPromise(
			enterprise
				.getPlaceProducts({ placeId })
				.then(res => {
					this.placeProducts = res;
				})
				.catch(err => showErrorNotification(err.message)),
		);
	};

	@action
	public fetchPlaceProduct = async (placeId: string, productId: string) => {
		try {
			return await enterprise.getPlaceProduct({ placeId, productId });
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@observable
	public productAtStorage: { [s: string]: StorageProduct[] } = {};

	@observable
	public arrayProductStorage:
		| { storageId: string; products: StorageProduct[] }[]
		| undefined = undefined;

	@action
	public getBackofficeProductsAtStorage = async (
		storageId: string,
		placeId: string | null,
	) => {
		try {
			const products = await enterprise.getProductsAtStorage({
				placeId,
				storageId: storageId,
				filters: { onlyStockableProducts: true },
			});
			this.productAtStorage[storageId] = products;
			this.arrayProductStorage = [
				...(this.arrayProductStorage || []),
				{ storageId: storageId, products },
			];
			return products;
		} catch (err) {
			console.error(err);
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public getProductsAtStorage = new fetchModel<
		{
			storageId: string;
			placeId: string | null;
			filters: { onlyStockableProducts: true };
		},
		StorageProduct[]
	>({
		fnPromise: args => enterprise.getProductsAtStorage(args),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public createNewSupplier = async (
		placeId: string,
		newSupplier: BackofficeNewSupplier,
	) => {
		try {
			const supplier = await enterprise.createBackofficeSupplier({
				placeId,
				supplier: newSupplier,
			});
			showSuccessNotification(t("store:backofficeStore.createNewSupplier"));
			this.fetchSuppliers(placeId);

			return supplier;
		} catch (err) {
			console.error(err);
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public inputBackofficeProductsV2 = async (
		placeId: string,
		inputs: BackofficeInputV2[],
		type: BackofficeStorageTransferType,
	) => {
		try {
			const result = await enterprise.inputBackofficeProductsV2({
				placeId,
				inputs,
				transferType: type,
				referenceCode: null,
			});

			if (result.error.length === 0) {
				showSuccessNotification(
					t("store:backofficeStore.inputBackofficeProductsV2_zero"),
				);
			} else {
				showErrorNotification(t("store:backofficeStore.inputBackofficeProductsV2_other"));
			}
		} catch (err) {
			console.error(err);
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public zeroBackofficeAllProductsAtStorage = new fetchModel<
		{
			placeId: string | null;
			storeId: string;
		},
		void
	>({
		fnPromise: ({ placeId, storeId }) =>
			enterprise.zeroBackofficeAllProductsAtStorage({ placeId, storageId: storeId }),
		onSuccess: () =>
			showSuccessNotification(
				t("store:backofficeStore.zeroBackofficeAllProductsAtStorage"),
			),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public editSupplier = async (
		placeId: string,
		id: string,
		supplier: BackofficeNewSupplier,
	) => {
		try {
			await enterprise.editBackofficeSupplier({ placeId, id, supplier });
			showSuccessNotification(t("store:backofficeStore.editSupplier"));
		} catch (err) {
			console.error(err);
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public saveBackofficeProductsQuantityV2 = async (
		placeId: string,
		product: StorageProduct,
		storageId: string,
		count: number,
		unitCostValue: string,
		obs: string,
	) => {
		try {
			if (count >= 0) {
				//api has two different calls to add or subtract products
				await enterprise.inputBackofficeProductV2({
					placeId,
					input: {
						productId: product.id,
						storageId,
						unitCostValue: unitCostValue,
						obs: obs || null,
						count,
						date: datetime().now(),
					},
					transferType: "manual",
					referenceCode: null,
				});
			} else {
				await enterprise.manualBackofficeAdjustProductAtStorage({
					placeId: placeId!,
					storageId: storageId,
					productId: product.id,
					count: -count, //manualAdjust requires a positive number to subtract in the total amount
					date: datetime().now(),
					obs: obs || null,
				});
			}

			const i = this.productAtStorage[storageId].indexOf(product);
			this.productAtStorage[storageId][i].quantity =
				this.productAtStorage[storageId][i].quantity + count;

			showSuccessNotification(
				t("store:backofficeStore.saveBackofficeProductsQuantityV2"),
			);
		} catch (err) {
			console.error(err);
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public deleteBackofficeProduct = async (
		placeId: string | null,
		productId: string,
		storageId: string,
	) => {
		try {
			await enterprise.deleteBackofficeProductAtStorage({
				placeId,
				storageId,
				productId,
			});
			showSuccessNotification(t("store:backofficeStore.deleteBackofficeProduct"));
		} catch (err) {
			console.error(err);
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public deleteBackofficeProducts = new fetchModel<
		{
			storageId: string;
			placeId: string | null;
			productIds: string[];
		},
		BackofficeDeleteProductsResult
	>({
		fnPromise: args => enterprise.deleteBackofficeProductsAtStorage(args),
		onSuccess: () =>
			showSuccessNotification(t("store:backofficeStore.deleteBackofficeProducts")),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public deleteSupplier = async (placeId: string, id: string) => {
		try {
			await enterprise.deleteBackofficeSupplier({ placeId, id });
			showSuccessNotification(t("store:backofficeStore.deleteSupplier"));
		} catch (err) {
			console.error(err);
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public transferBackofficeProduct = async (
		productId: string,
		fromId: string,
		toId: string,
		count: number,
		placeId: string | null,
	) => {
		try {
			await enterprise.transferBackofficeProduct({
				placeId,
				productId,
				fromId,
				toId,
				count,
				date: datetime().now(),
			});

			showSuccessNotification(t("store:backofficeStore.transferBackofficeProduct"));
			this.getBackofficeProductsAtStorage(fromId, placeId);
			this.getBackofficeProductsAtStorage(toId, placeId);
		} catch (err) {
			console.error(err);
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public transferBackofficeProducts = new fetchModel<
		{
			placeId: string | null;
			fromId: string;
			toId: string;
			products: BackofficeMultipleStorageTransferProduct[];
		},
		BackofficeMultipleStorageTransferProductResult
	>({
		fnPromise: ({ placeId, fromId, products, toId }) =>
			enterprise.transferBackofficeProducts({
				placeId,
				fromId,
				toId,
				date: datetime().now(),
				products,
			}),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public fetchTypeReport = (placeId: string, type: BackofficeBillStatusFilter) => {
		this.typeReportPromise = fromPromise(
			enterprise
				.getBackofficeBillTypeReport({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: type,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.typeReport = v;
				})
				.catch(err => showErrorNotification(err.message)),
		);
	};

	@action
	public fetchCategoryReport = (placeId: string, type: BackofficeBillStatusFilter) => {
		this.categoryReportPromise = fromPromise(
			enterprise
				.getBackofficeBillCategoryReport({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: type,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.categoryReport = v;
				})
				.catch(err => showErrorNotification(err.message)),
		);
	};

	@action
	public fetchCategoryHistory = (placeId: string, type: BackofficeBillStatusFilter) => {
		this.categoryHistoryPromise = fromPromise(
			enterprise
				.getBackofficeCategoryHistoryReport({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: type,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.categoryHistory = v;
				})
				.catch(err => showErrorNotification(err.message)),
		);
	};

	public fetchCostCenterReport = (placeId: string, type: BackofficeBillStatusFilter) => {
		this.costCenterPromise = fromPromise(
			enterprise
				.getBackofficeCostCenterReport({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: type,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.costCenterReport = v;
				})
				.catch(err => showErrorNotification(err.message)),
		);
	};

	public fetchPaidOrReceiveReport = (
		placeId: string,
		type: BackofficeBillStatusFilter,
	) => {
		this.paidOrReceivedPromise = fromPromise(
			enterprise
				.getBackofficeBillSupplierTrackerReport({
					placeId,
					since: this.since,
					until: this.until,
					statusFilter: type,
					dateFilter: this.dateFilter,
				})
				.then(v => {
					this.paidOrReceivedReport = v;
				})
				.catch(err => showErrorNotification(err.message)),
		);
	};

	@action
	public fetchBackofficeResume = (placeId: string) => {
		this.backofficeResumePromise = fromPromise(
			enterprise
				.getBackofficeResume({ placeId })
				.then(v => {
					this.backofficeResume = v;
				})
				.catch(err => showErrorNotification(err.message)),
		);
		return this.backofficeResumePromise;
	};

	@action
	public fetchBackofficeResumeInPeriod = (placeId: string) => {
		this.backofficeResumePromise = fromPromise(
			enterprise
				.getBackofficeResumeInPeriod({ placeId, since: this.since, until: this.until })
				.then(v => {
					this.backofficeResumeInPeriod = v;
				})
				.catch(err => showErrorNotification(err.message)),
		);
		return this.backofficeResumePromise;
	};

	public backofficeImportedInvoices = new fetchModel<
		{
			placeId: string;
			since?: Date;
			until?: Date;
		},
		BackofficeImportedInvoice[]
	>({
		fnPromise: ({ placeId, since, until }) =>
			enterprise.getBackofficeImportedInvoices({ placeId, since: since, until }),
		onError: err => showErrorNotification(err.message),
	});

	public deleteBackofficeImportedInvoice = new fetchModel<
		{ placeId: string; invoiceId: string },
		void
	>({
		fnPromise: ({ placeId, invoiceId }) =>
			enterprise.deleteBackofficeImportedInvoice({ placeId, invoiceId }),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => showSuccessNotification(t("store:backofficeStore.deleteInvoices")),
	});

	@action
	public fetchBackofficeProductStorageHistory = async (
		placeId: string,
		storageId: string,
		productId: string,
		since: Date,
		until: Date,
		pageNumber: number,
		filterBy: BackofficeProductStorageHistoryFilterBy,
		itemsPerPage?: number | null,
	) => {
		try {
			this.productHistory = undefined;
			const result = await enterprise.getBackofficeProductStorageHistoryV3({
				placeId,
				storageId,
				productId,
				since,
				until,
				page: pageNumber,
				itemsPerPage: itemsPerPage || 30,
				filterBy,
			});
			this.productHistory = result;
			return result;
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public createBackofficeProductStorageHistoryXlsx = async (
		placeId: string,
		storageId: string,
		productId: string,
		since: Date,
		until: Date,
		filterBy: BackofficeProductStorageHistoryFilterBy,
	) => {
		try {
			const dataUrl = await enterprise.getBackofficeProductStorageHistoryXls({
				placeId,
				storageId,
				productId,
				since,
				until,
				filterBy,
			});
			return dataUrl;
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public getBackofficeCashFlowV2 = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
			groupBy: GetBackofficeCashFlowV2GroupBy;
		},
		BackofficeAccountBillsReportResponse | null
	>({
		fnPromise: args => enterprise.getBackofficeCashFlowV2(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeDRE = new fetchModel<
		{ placeId: string; interval: BackofficeParamDates },
		BackofficeAccountBillsReportResponse | null
	>({
		fnPromise: args => enterprise.getBackofficeDRE(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeAccountBills = new fetchModel<
		{ placeId: string; id: string; type: BackofficeTypeAccountBills },
		BackofficeGetAccountBillsResponse | null
	>({
		fnPromise: args => enterprise.getBackofficeAccountBills(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeSuppliers = new fetchModel<
		{ placeId: string },
		BackofficeSupplier[]
	>({
		fnPromise: args => enterprise.getBackofficeSuppliers(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeCostCenters = new fetchModel<
		{ placeId: string },
		BackofficeCostCenter[]
	>({
		fnPromise: args => enterprise.getBackofficeCostCenters(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeAccounts = new fetchModel<{ placeId: string }, BackofficeAccount[]>(
		{
			fnPromise: args => enterprise.getBackofficeAccounts(args),
			onError: err => showErrorNotification(err.message),
		},
	);

	public getEventClosingDataToConference = new fetchModel<
		{ eventId: string; placeId: string },
		FinancialEventClosingConference
	>({
		fnPromise: args => enterprise.getEventClosingDataToConference(args),
		onError: err => showErrorNotification(err.message),
	});

	public createBackofficeAccountBillsOnClosingEvent = new fetchModel<
		{ eventId: string; placeId: string; eventName: string },
		void
	>({
		fnPromise: args => enterprise.createBackofficeAccountBillsOnClosingEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeStorageInventories = new fetchModel<
		{
			placeId: string;
			status?: BackofficeStorageInventoryStatus | null;
			orderBy?: GetBackofficeStorageInventoriesOrderBy | null;
			order?: GetBackofficeStorageInventoriesOrder | null;
			since?: Date | null;
			until?: Date | null;
			page?: number | null;
			itemsPerPage?: number | null;
			query?: string | null;
		},
		BackofficeStorageInventoriesResult
	>({
		fnPromise: args => enterprise.getBackofficeStorageInventories(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeStorageInventory = new fetchModel<
		{ placeId: string; id: string },
		CompleteBackofficeStorageInventory
	>({
		fnPromise: args => enterprise.getBackofficeStorageInventory(args),
		onError: err => showErrorNotification(err.message),
	});

	public updateBackofficeStorageInventoryProducts = new fetchModel<
		{
			placeId: string;
			storageInventoryId: string;
			products: BackofficeStorageInventoryProduct[];
		},
		CompleteBackofficeStorageInventory
	>({
		fnPromise: args => enterprise.updateBackofficeStorageInventoryProducts(args),
		onError: err => showErrorNotification(err.message),
	});

	public createBackofficeStorageInventory = new fetchModel<
		{
			placeId: string;
			name: string;
			storageId: string;
			categoriesIds: string[];
		},
		BackofficeStorageInventoryWithStorage
	>({
		fnPromise: args => enterprise.createBackofficeStorageInventory(args),
		onError: err => showErrorNotification(err.message),
	});

	public updateBackofficeStorageInventoryStatus = new fetchModel<
		{
			placeId: string;
			storageInventoryId: string;
			status: BackofficeStorageInventoryStatus;
		},
		BackofficeStorageInventoryWithStorage
	>({
		fnPromise: args => enterprise.updateBackofficeStorageInventoryStatus(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeStorageInventoryAndStorageComparison = new fetchModel<
		{
			placeId: string;
			storageInventoryId: string;
			filters: GetBackofficeStorageInventoryAndStorageComparisonFilters;
			order: GetBackofficeStorageInventoryAndStorageComparisonOrder | null;
			orderBy: GetBackofficeStorageInventoryAndStorageComparisonOrderBy | null;
			pagination: Pagination;
		},
		BackofficeStorageInventoryAndStorageComparison
	>({
		fnPromise: args => enterprise.getBackofficeStorageInventoryAndStorageComparison(args),
		onError: err => showErrorNotification(err.message),
	});

	public exportBackofficeStorageInventoryAndStorageComparison = new fetchModel<
		{
			placeId: string;
			storageInventoryId: string;
			filters: GetBackofficeStorageInventoryAndStorageComparisonFilters;
			pagination: Pagination;
		},
		string
	>({
		fnPromise: args =>
			enterprise.exportBackofficeStorageInventoryAndStorageComparison(args),
		onError: err => showErrorNotification(err.message),
	});

	public createBar = new fetchModel<
		{
			placeId: string;
			barName: string;
			storageId: string;
			internalIp?: string | null | undefined;
			internalIps?: string[] | null | undefined;
			fichaLogo?: ArrayBuffer | null | undefined;
		},
		string
	>({
		fnPromise: args => enterprise.addBar(args),
		onSuccess: () => {
			ReactGA.event({
				category: "User",
				action: "create bar",
			});
		},
		onError: err => {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		},
	});

	public deleteBackofficeStorageInventory = new fetchModel<
		{
			placeId: string;
			storageInventoryId: string;
		},
		void
	>({
		fnPromise: args => enterprise.deleteBackofficeStorageInventory(args),
		onSuccess: () => showSuccessNotification("Inventário excluido com sucesso"),
		onError: () =>
			showErrorNotification(
				"Ocorreu um erro na exclusão do inventário. Tente novamente mais tarde.",
			),
	});

	public getBackofficeSuppliersXLSX = new fetchModel<{ placeId: string }, string>({
		fnPromise: args => enterprise.getBackofficeSuppliersXLSX(args),
		onSuccess: url => window.open(url, "_blank"),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeSupplierAbcCurve = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
			pagination: Pagination;
			supplierName?: string | null;
		},
		SupplierAbcCurve | null
	>({
		fnPromise: args => enterprise.getBackofficeSupplierAbcCurve(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeProductAbcCurve = new fetchModel<
		{ placeId: string; since: Date; until: Date; productName?: string | null },
		ProductAbcCurve | null
	>({
		fnPromise: args => enterprise.getBackofficeProductAbcCurve(args),
		onError: err => showErrorNotification(err.message),
	});

	public exportBackofficeSupplierAbcCurveReport = new fetchModel<
		{ placeId: string; since: Date; until: Date; supplierName?: string | null },
		string
	>({
		fnPromise: args => enterprise.exportBackofficeSupplierAbcCurveReport(args),
		onSuccess: url => window.open(url, "_blank"),
		onError: err => showErrorNotification(err.message),
	});

	public exportBackofficeProductAbcCurveReport = new fetchModel<
		{ placeId: string; since: Date; until: Date; productName?: string | null },
		string
	>({
		fnPromise: args => enterprise.exportBackofficeProductAbcCurveReport(args),
		onSuccess: url => window.open(url, "_blank"),
		onError: err => showErrorNotification(err.message),
	});

	public getStoragesTransfersHistory = new fetchModel<
		{
			placeId: string;
			filters: GetStoragesTransfersHistoryByContextFilters;
		},
		StorageTransferHistoryResult
	>({
		fnPromise: args => enterprise.getStoragesTransfersHistory(args),
		onError: err => showErrorNotification(err.message),
	});

	public exportStoragesTransfersHistory = new fetchModel<
		{ placeId: string; filters: GetStoragesTransfersHistoryByContextFilters },
		string
	>({
		fnPromise: args => enterprise.exportStoragesTransfersHistory(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeTransfersBetweenStorages = new fetchModel<
		{
			placeId: string;
			orderBy?: GetBackofficeTransfersBetweenStoragesOrderBy | null;
			order?: GetBackofficeTransfersBetweenStoragesOrder | null;
			filters: GetBackofficeTransfersBetweenStoragesFilters;
		},
		BackofficeTransfersBetweenStoragesResult
	>({
		fnPromise: args => enterprise.getBackofficeTransfersBetweenStorages(args),
		onError: err => showErrorNotification(err.message),
	});

	public exportBackofficeTransfersBetweenStorages = new fetchModel<
		{
			placeId: string;
			orderBy?: ExportBackofficeTransfersBetweenStoragesOrderBy | null;
			order?: ExportBackofficeTransfersBetweenStoragesOrder | null;
			filters: BaseGetBackofficeTransfersBetweenStoragesFilters;
		},
		string
	>({
		fnPromise: args => enterprise.exportBackofficeTransfersBetweenStorages(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: url => window.open(url, "_blank"),
	});
	public deleteBackofficeStorageInventoryProducts = new fetchModel<
		{
			placeId: string;
			storageInventoryId: string;
			productsIds: string[];
		},
		string[]
	>({
		fnPromise: args => enterprise.deleteBackofficeStorageInventoryProducts(args),
		onSuccess: () =>
			showSuccessNotification(
				t("place:inventoryControl.stepsInventoryControl.count.notification.success"),
			),
		onError: err => showErrorNotification(err.message),
	});

	public updateBackofficeStorageInventoryProductsTransferType = new fetchModel<
		{
			placeId: string;
			storageInventoryId: string;
			products: BackofficeUpdateStorageInventoryProductsTransferType[];
		},
		void
	>({
		fnPromise: args =>
			enterprise.updateBackofficeStorageInventoryProductsTransferType(args),
		// onSuccess: () =>
		// 	showSuccessNotification(
		// 		t("place:inventoryControl.stepsInventoryControl.count.notification.success"),
		// 	),
		onError: err => showErrorNotification(err.message),
	});

	public getBackofficeStorageInventoryTopInconsistencies = new fetchModel<
		{
			placeId: string;
			storageInventoryId: string;
			limit: number;
			type: GetBackofficeStorageInventoryTopInconsistenciesType;
		},
		BackofficeStorageInventoryProductInconsistency[]
	>({
		fnPromise: args => enterprise.getBackofficeStorageInventoryTopInconsistencies(args),
		onError: err => showErrorNotification(err.message),
	});
}
