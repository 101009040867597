import React from "react";
import { TableProductsSold } from "../table-products-sold/table-products-sold";
import { useTranslation } from "react-i18next";
import { sumField } from "../../helpers/calc";
import { useSalesReport } from "../../context";
import { LoadingOutlined } from "@ant-design/icons";
import { useProductsSoldByBarReport } from "#resources/hooks/integrations/products/query";
import { TableTotalPagination } from "#components/table-components/table-total-pagination";

import s from "./table-products-sold-by-bar.module.scss";

export const TableProductsSoldByBar = () => {
	const {
		placeId,
		since,
		until,
		selectedTab,
		groupBy,
		barsFilter,
		transactionsFilter,
		productsFilter,
	} = useSalesReport();

	const barIds = barsFilter.length ? barsFilter : null;
	const productIds = productsFilter.length ? productsFilter : null;
	const sources = transactionsFilter.length ? transactionsFilter : null;

	const {
		data: dataSourceProductsByBar = [],
		isLoading: isLoadingProductsSoldByBar,
	} = useProductsSoldByBarReport(
		{
			placeId,
			since,
			until,
			filters: {
				barIds,
				productIds,
				sources,
			},
		},
		{
			enabled: selectedTab === "total-sales" && groupBy === "bar",
			retry: false,
		},
	);

	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport.totalSales.tableProductsSold",
	});

	return (
		<div className={s.barTables}>
			{isLoadingProductsSoldByBar && <LoadingOutlined />}

			{dataSourceProductsByBar.map(bar => {
				const { products, barName = "" } = bar;

				const countAmount = sumField(products, "count");
				const subtotalAmount = sumField(products, "subtotal");
				const discountAmount = sumField(products, "discount");
				const totalAmount = sumField(products, "totalValue");

				return (
					<div className={s.tableBarWrapper} key={bar.barId}>
						<strong>{barName}</strong>

						<TableProductsSold
							dataSource={products}
							TableProps={{
								pagination: {
									showTotal: (total, range) => (
										<TableTotalPagination total={total} range={range} />
									),
									showSizeChanger: true,
									pageSize: 5,
								},
							}}
							totalizers={{
								totalDescription: t("totalInBar", {
									barName,
								}),
								countAmount,
								subtotalAmount,
								discountAmount,
								totalAmount,
							}}
						/>
					</div>
				);
			})}
		</div>
	);
};
