export const performanceTableReport = {
	tabName: "Performance das mesas",
	label: "Relatórios de mesas",
	title: "Acompanhe o desempenho das mesas e potencialize a gestão do seu restaurante.",
	filters: {
		table: "Todas as mesas",
	},
	cards: {
		totalBilling: "Faturamento total",
		peopleServed: "Pessoas atendidas",
		averageTicketPerTable: "Ticket médio por mesa",
		averageTicketPerPerson: "Ticket médio por pessoa",
		averageSpins: "Média de giros",
		AverageLengthOfStay: "Tempo médio de permanência",
		tableCombinations: "Combinações de mesas",
	},
	table: {
		title: "Consumo de mesas",
		columns: {
			tableName: "MESA",
			employeeName: "GARÇOM",
			numberOfSeats: "PESSOAS",
			openedAt: "ABERTURA",
			permanence: "PERMANÊNCIA",
			tip: "SERVIÇO",
			productsValue: "PRODUTOS",
			closedAt: "FECHAMENTO",
			discount: "DESCONTOS",
			refundValue: "ESTORNOS",
			totalValue: "TOTAL",
			products: {
				imageUrl: "FOTO",
				sku: "SKU",
				name: "NOME DO PRODUTO",
				quantity: "QUANTIDADE",
				unitPrice: "VALOR UNITÁRIO",
				subTotal: "SUBTOTAL",
				discount: "DESCONTOS",
				total: "VALOR TOTAL",
			},
		},
	},
};
