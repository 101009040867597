import { formatter } from "./formatter";

export const promotion = {
	defaultName: "Copy of {{promotion}}",
	errors: {
		nameLength: "The promotion name has not reached the minimum number of characters.",
		sameName: "The copy name is the same as the original name.",
		notFilledCorrectly: "The fields were not filled in correctly.",
	},

	copyModal: {
		okText: "Copy",
		title: "Create promotion copy",
		message: "Do you want to create a copy of the promotion",
		placeholder: "Promo copy name",
	},
	peopleTable: {
		example: "example worksheet",
		error: "Invalid format",
		import: "Import Sheet",
		download: "Download sample worksheet",
	},
	categoryModal: {
		title: "Add New Product",
		label: "Product or category",
		placeholder: "Search for a product or category",
		noneProduct: "No products found",
	},
	creationStep: {
		formatter,
		error: "Unable to redirect page.",
		steps: {
			promotionInfo: "Promotion Data",
			goals: "Goals",
			awards: "Awards",
			usageConditions: "Conditions of Use",
			resume: "Summary",
		},
		stepsPages: {
			goals: {
				tableWarningMessage: "Information not available for Tables.",
				label: "Recurrence of earnings",
				placeholder: "Select a maximum recurrence of earnings",
				error: "Condition ALL CLIENTS needs to be removed",
				new: "New goal",
				add: "Add goal",
				conditions: {
					birthday: {
						month: "Birthday of the month",
						week: "Birthday boy of the week",
						day: "Birthday Boy",
					},
					checkin: {
						label: "Arrival time",
					},
					gender: {
						male: "Men",
						female: "Women",
						others: "Others",
					},
					minimumRecharge: {
						label: "Minimum recharge amount",
					},
				},
			},
			usageConditions: {
				conditions: {
					expiration: {
						label: "Calendar days:",
						suffix: "Days",
					},
					period: {
						beginTime: "From:",
						endTime: "Until:",
						error: "Invalid time format for conversion",
					},
					week: {
						infoText:
							"As events can start on one day of the week and end on the other, the promotion is valid on the day the event starts. So the customer can use the promotion the next day if the event started the day before.",
						validPromotion: "Valid Promotion",
					},
					modal: {
						new: "New usage condition",
						add: "Add usage condition",
					},
				},
			},
		},
		stepsWrapper: {
			title: "Title not found",
			description: "Description not found",
			save: "Save changes",
		},
	},
	table: {
		goals: {
			label: "Promotion target list",
			empty: "No goal",
			new: "New goal",
		},
		usageConditions: {
			label: "List of conditions of use of the promotion",
			empty: "No usage conditions",
			new: "New usage condition",
		},
	},
};
