/* eslint-disable max-len */
export const PURCHASE_INVOICE_PT_BR = {
	importationInvoices: {
		cards: {
			importXml: {
				title: "Importação via XML",
				description: "Importe suas notas fiscais via arquivos XML",
				button: "Click para importar",
			},
			importManual: {
				title: "Entrada manual",
				description: "Dê entrada em suas notas fiscais de forma manual",
				button: "Click para entrada manual",
			},
			importSefaz: {
				title: "Importação via Sefaz",
				description: "Importe as notas fiscais diretamente do Sefaz",
				button: "Click para importar",
			},
		},
		importXml: {
			input: {
				title: "Selecione o XML para iniciar a importação",
				description: "Você pode clicar em “Selecionar arquivo” ou arraste um aquivo",
				button: "Selecionar XML",
			},
			labels: {
				storage: "Estoque",
				selectStorage: "Selecione um estoque",
				xmlInvoice: "XML da nota fiscal",
				changeXml: "Trocar XML",
				back: "Voltar",
				import: "Importar",
			},
			selectedFile: "O arquivo selecionado é: ",
			xmlRequiredMessage: "O arquivo XML é obrigatório.",
			storageIdRequiredMessage: "Selecione um estoque.",
			uploadErrorMessage: "Não foi possível ler o arquivo.",
		},
	},
};
export type PurchaseInvoiceTranslations = typeof PURCHASE_INVOICE_PT_BR;
