/* eslint-disable max-len */
export const IMPORT_PT_BR = {
	title: "Importação de XML",
	invoiceData: {
		title: "Dados da nota fiscal",
		billNumber: "Número da nota",
		noteSeries: "Série da nota",
		natureOfOperation: "Natureza da operação",
		accesskey: "Chave de acesso",
		issuer: "Emitente",
		CNPJAndCPF: "CNPJ/CPF",
		noteValue: "Valor da Nota",
		discount: "Desconto",
		totalValue: "Valor total",
		dateInputLabel: "Data de entrada no estoque físico:",
	},
	duplicates: {
		title: "Duplicatas",
		duplicate: "Duplicata",
		dueDate: "Vencimento",
		value: "Valor",
	},
	payment: {
		title: "Pagamento",
		cashPayment: "Pagamento à vista",
		installmentsPayment: "Parcelamento",
		payment: "Pagamento",
	},
	ShippingAndExpenses: {
		title: "Frete e despesas",
		shipping: "Frete",
		insurance: "Seguro",
		additionalExpenses: "Despesas acessórias",
	},
	infoCalculator:
		"A base do cálculo do custo unitário é: Valor total - Desconto + IPI + ICMS + Frete + Seguro + Outras despesas / Quantidade",
	product: {
		amount: "Quantidade",
		commercialUnit: "Unidade de Medida",
		unitValue: "Pago por unidade",
	},
	invoiceDetailsFooterButtons: {
		cancel: "Cancelar",
		next: "Próximo",
	},
	newProductModal: {
		triggerText: "Clique aqui para cadastrá-lo.",
		modalTitle: "Adicionar item",
		contentText: "O que você está cadastrando?",
		insumo: {
			title: "Insumo",
			description:
				"Podem ser utilizados como ingredientes em receitas de outros produtos e não aparecem em seus pontos de venda.",
			action: "Clique para criar novo insumo",
		},
		produto: {
			title: "Produto",
			description:
				"Produtos finais de venda que podem utilizar, ou não, insumos na sua produção.",
			action: "Clique para criar novo produto",
		},
	},
};
export type ImportTranslations = typeof IMPORT_PT_BR;
