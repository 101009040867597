import { autobind } from "core-decorators";
import { BarModel } from "#models/bar";
import enterprise from "#resources/api/enterprise-client";
import { fetchModel } from "#helpers/fetch-model";
import { IError } from "#helpers/mobx/utils";
import { RootStore } from ".";
import {
	BackofficeImportedInvoice,
	BackofficeImportedInvoiceRestriction,
	BackofficeImportedProductsFromInvoices,
	CEST,
	ConsumptionObligationConfig,
	Couvert,
	EventResumeSection,
	FiscalInvoiceBackup,
	FiscalProductGroup,
	FiscalProfile,
	ImageUrl,
	InvoiceIssuedByMode,
	InvoiceIssuedByPerfilFiscal,
	IssueResult,
	ManualRequest,
	NotEmittedProduct,
	NotIssuedWithErrorResponse,
	PlaceConsumptionObligationConfig,
	PlaceProduct,
	PlaceRechargeFiscalConfig,
	PlaceSellVisualizationFormat,
	ProductFiscalData,
	ProductFiscalDataWithPlaceInfo,
	RechargeFiscalConfigPayload,
	ReportFiscal,
	SimpleInvoiceInfo,
} from "#resources/api/enterprise-generated";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";
import i18n from "#i18n/index";
// import { mockedValues } from "#pages/place/emission-errors/mock";

export interface placeWithFiscalData {
	id: string | null;
	name: string;
	image: ImageUrl | null;
	postPaidLimit: number | null;
	maleCouvert: Couvert | null;
	femaleCouvert: Couvert | null;
	bars: BarModel[];
	tip: number;
	zigTagProduct: PlaceProduct | null;
	sellVisualizationFormat: PlaceSellVisualizationFormat;
	codigo: string | null;
	ncm: string | null;
	cest: string | null;
	fiscalProfileId: string | null;
	fiscalProductGroupId: string | null;
}

const t = i18n.t;

@autobind
export class FiscalStore {
	public rootStore: RootStore;

	public constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.setCouvertFiscalData.reset();
		this.getCouvertFiscalData.reset();
		this.getCouvertFiscalDataForPlaces.reset();
		this.getFiscalProfile.reset();
		this.getFiscalProfiles.reset();
		this.getFiscalProfilesAtPlace.reset();
		this.getFiscalProductGroupsAtPlace.reset();
		this.deleteFiscalProfile.reset();
		this.createFiscalProfile.reset();
		this.updateFiscalProfile.reset();
		this.getFiscalProductGroup.reset();
		this.getFiscalProductGroups.reset();
		this.createFiscalProductGroup.reset();
		this.updateFiscalProductGroup.reset();
		this.deleteFiscalProductGroup.reset();
		this.getReportFiscal.reset();
		this.getReportFiscalXlsx.reset();
		this.issueManualInvoiceForReserve.reset();
		this.getNotIssuedWithError.reset();
		this.getFiscalSumupSections.reset();
		this.getInvoiceIssuedByMode.reset();
		this.getReportNotIssuedWithErrorXlsx.reset();
		this.getInvoiceIssuedByPerfilFiscal.reset();
		this.getNotEmittedProduct.reset();
	}

	// COUVERT
	public setCouvertFiscalData = new fetchModel<
		{
			placeId: string;
			data: ProductFiscalData;
		},
		void
	>({
		fnPromise: args => enterprise.setCouvertFiscalData(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getCouvertFiscalData = new fetchModel<
		{ placeId: string },
		ProductFiscalDataWithPlaceInfo | null
	>({
		fnPromise: args => enterprise.getCouvertFiscalData(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getCouvertFiscalDataForPlaces = new fetchModel<
		{},
		ProductFiscalDataWithPlaceInfo[]
	>({
		fnPromise: args => enterprise.getCouvertFiscalDataForPlaces(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	// FISCAL PROFILE
	public getFiscalProfile = new fetchModel<{ fiscalProfileId: string }, FiscalProfile>({
		fnPromise: args => enterprise.getFiscalProfile(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	// SHOULD BE USED ONLY AT ORG LEVEL
	public getFiscalProfiles = new fetchModel<{}, FiscalProfile[]>({
		fnPromise: args => enterprise.getFiscalProfiles(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	// SHOULD BE USED AT PLACE/EVENT LEVEL
	public getFiscalProfilesAtPlace = new fetchModel<{ placeId: string }, FiscalProfile[]>({
		fnPromise: args => enterprise.getFiscalProfilesAtPlace(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public deleteFiscalProfile = new fetchModel<{ id: string }, void>({
		fnPromise: args => enterprise.deleteFiscalProfile(args),
		onSuccess: _ => this.getFiscalProfiles.fetch({}),
	});

	public createFiscalProfile = new fetchModel<
		{ fiscalProfile: FiscalProfile },
		FiscalProfile
	>({
		fnPromise: args => enterprise.createFiscalProfile(args),
		onSuccess: _ => this.getFiscalProfiles.fetch({}),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public updateFiscalProfile = new fetchModel<
		{ fiscalProfile: FiscalProfile },
		FiscalProfile
	>({
		fnPromise: args => enterprise.updateFiscalProfile(args),
		onSuccess: _ => this.getFiscalProfiles.fetch({}),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public setFiscalProfileActivePlaces = new fetchModel<
		{ fiscalProfileId: string; placeIds?: string[] | null },
		void
	>({
		fnPromise: args => enterprise.setFiscalProfileActivePlaces(args),
		onSuccess: () => showSuccessNotification("Locais atualizados com sucesso!"),
		onError: err => showErrorNotification(err.message),
	});

	public getFiscalProfileActivePlaces = new fetchModel<
		{ fiscalProfileId: string },
		string[] | null
	>({
		fnPromise: args => enterprise.getFiscalProfileActivePlaces(args),
		onError: err => showErrorNotification(err.message),
	});

	// FISCAL PRODUCT GROUP
	public getFiscalProductGroup = new fetchModel<
		{ fiscalProductGroupId: string },
		FiscalProductGroup
	>({
		fnPromise: args => enterprise.getFiscalProductGroup(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	// SHOULD BE USED ONLY AT ORG LEVEL
	public getFiscalProductGroups = new fetchModel<{}, FiscalProductGroup[]>({
		fnPromise: args => enterprise.getFiscalProductGroups(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	// SHOULD BE USED AT PLACE/EVENT LEVEL
	public getFiscalProductGroupsAtPlace = new fetchModel<
		{ placeId: string },
		FiscalProductGroup[]
	>({
		fnPromise: args => enterprise.getFiscalProductGroupsAtPlace(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public createFiscalProductGroup = new fetchModel<
		{ fiscalProductGroup: FiscalProductGroup },
		FiscalProductGroup
	>({
		fnPromise: args => enterprise.createFiscalProductGroup(args),
		onSuccess: _ => this.getFiscalProductGroups.fetch({}),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public updateFiscalProductGroup = new fetchModel<
		{ fiscalProductGroup: FiscalProductGroup },
		FiscalProductGroup
	>({
		fnPromise: args => enterprise.updateFiscalProductGroup(args),
		onSuccess: _ => this.getFiscalProductGroups.fetch({}),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public deleteFiscalProductGroup = new fetchModel<{ id: string }, void>({
		fnPromise: args => enterprise.deleteFiscalProductGroup(args),
		onSuccess: _ => this.getFiscalProductGroups.fetch({}),
	});

	public getFiscalProductGroupActivePlaces = new fetchModel<
		{ fiscalProductGroupId: string },
		string[] | null
	>({
		fnPromise: args => enterprise.getFiscalProductGroupActivePlaces(args),
		onError: err => showErrorNotification(err.message),
	});

	public setFiscalProductGroupActivePlaces = new fetchModel<
		{ fiscalProductGroupId: string; placeIds?: string[] | null },
		void
	>({
		fnPromise: args => enterprise.setFiscalProductGroupActivePlaces(args),
		onSuccess: () => showSuccessNotification("Locais atualizados com sucesso!"),
		onError: err => showErrorNotification(err.message),
	});

	// OTHERS
	public getReportFiscal = new fetchModel<
		{
			placeId: string;
			fiscalProfileIds: string[];
			since: Date;
			until: Date;
		},
		ReportFiscal[]
	>({
		fnPromise: args => enterprise.getReportFiscal(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getReportFiscalXlsx = new fetchModel<
		{
			placeId: string;
			fiscalProfileIds: string[];
			since: Date;
			until: Date;
		},
		string
	>({
		fnPromise: args => enterprise.getReportFiscalXlsx(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public issueManualInvoiceForReserve = new fetchModel<
		{
			manualRequest: ManualRequest;
			reserveId: string;
			cpf: string | null;
			cnpj: string | null;
			nif: string | null;
		},
		IssueResult[]
	>({
		fnPromise: args =>
			enterprise.issueManualInvoiceForReserve({
				eventId: this.rootStore.eventStore.event!.id,
				manualRequest: args.manualRequest,
				cpf: args.cpf,
				cnpj: args.cnpj,
				nif: args.nif,
				reserveId: args.reserveId,
			}),
		onSuccess: async res => {
			res.forEach(request => {
				if (request.failedInvoiceCount > 0) {
					showErrorNotification(
						t("store:fiscalStore.issueManualInvoiceForReserveError_zero", {
							failedInvoiceCount: request.failedInvoiceCount,
						}),
					);
					request.errors.map((er, i) =>
						showErrorNotification(
							t("store:fiscalStore.issueManualInvoiceForReserveError", {
								number: i,
								error: String(er),
							}),
						),
					);
				}

				if (request.issuedInvoices.length > 0) {
					if (request.failedInvoiceCount === 0)
						showSuccessNotification(
							t("store:fiscalStore.issueManualInvoiceForReserve_zero"),
						);
					else
						showSuccessNotification(
							t("store:fiscalStore.issueManualInvoiceForReserve_other", {
								issuedInvoices: request.issuedInvoices,
							}),
						);
				}
			});
		},
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getNotIssuedWithError = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
			currentPage: number;
		},
		NotIssuedWithErrorResponse
	>({
		fnPromise: args => enterprise.getNotIssuedWithError(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getFiscalSumupSections = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		EventResumeSection[]
	>({
		fnPromise: args => enterprise.getFiscalSumupSections(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getInvoiceIssuedByMode = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		InvoiceIssuedByMode
	>({
		fnPromise: args => enterprise.getInvoiceIssuedByMode(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getReportNotIssuedWithErrorXlsx = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		string
	>({
		fnPromise: args => enterprise.getReportNotIssuedWithErrorXlsx(args),
		onSuccess: url => window.open(url, "_blank"),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getInvoiceIssuedByPerfilFiscal = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		InvoiceIssuedByPerfilFiscal[]
	>({
		fnPromise: args => enterprise.getInvoiceIssuedByPerfilFiscal(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getNotEmittedProduct = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		NotEmittedProduct[]
	>({
		fnPromise: args => enterprise.getNotEmittedProduct(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getBackofficeImportedProductsFromInvoices = new fetchModel<
		{
			placeId: string;
			since?: Date | null;
			until?: Date | null;
		},
		BackofficeImportedProductsFromInvoices[]
	>({
		fnPromise: args => enterprise.getBackofficeImportedProductsFromInvoices(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public importBackofficeInvoice = new fetchModel<
		{
			placeId: string;
			storageId?: string | null;
			xml: ArrayBuffer;
			restriction?: BackofficeImportedInvoiceRestriction | null;
		},
		BackofficeImportedInvoice
	>({
		fnPromise: args =>
			enterprise.importBackofficeInvoice({ ...args, xml: Buffer.from(args.xml) }),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getBackofficeImportedInvoiceById = new fetchModel<
		{
			placeId: string;
			id: string;
		},
		BackofficeImportedInvoice
	>({
		fnPromise: args => enterprise.getBackofficeImportedInvoiceById(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getCESTbyNCM = new fetchModel<
		{
			ncmId: string;
		},
		CEST[]
	>({
		fnPromise: args => enterprise.getCESTbyNCM(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public undoBackofficeFinishInvoiceImport = new fetchModel<
		{ placeId: string; invoiceId: string },
		void
	>({
		fnPromise: args => enterprise.undoBackofficeFinishInvoiceImport(args),
		onSuccess: () => showSuccessNotification("Notas desprocessadas com sucesso"),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getFiscalProfileInvoiceOptions = new fetchModel<
		{
			fiscalProfileId: string;
			placeId: string;
		},
		SimpleInvoiceInfo[]
	>({
		fnPromise: args => enterprise.getFiscalProfileInvoiceOptions(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getFiscalInvoicesBackups = new fetchModel<
		{
			fiscalProfileId: string;
			placeId: string;
		},
		FiscalInvoiceBackup[]
	>({
		fnPromise: args => enterprise.getFiscalInvoicesBackups(args),
	});

	public importFiscalProfileInvoiceFromSefaz = new fetchModel<
		{
			placeId: string;
			storageId: string;
			fiscalProfileId: string;
			invoiceKey: string;
		},
		BackofficeImportedInvoice
	>({
		fnPromise: args => enterprise.importFiscalProfileInvoiceFromSefaz(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getConsumptionObligationConfigForPlace = new fetchModel<
		{ placeId: string },
		PlaceConsumptionObligationConfig
	>({
		fnPromise: args => enterprise.getConsumptionObligationConfigForPlace(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getConsumptionObligationConfigForPlaces = new fetchModel<
		{},
		PlaceConsumptionObligationConfig[]
	>({
		fnPromise: args => enterprise.getConsumptionObligationConfigForPlaces(args),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public setConsumptionObligationConfigForPlace = new fetchModel<
		{ placeId: string; config?: ConsumptionObligationConfig | null },
		PlaceConsumptionObligationConfig
	>({
		fnPromise: args => enterprise.setConsumptionObligationConfigForPlace(args),
		onSuccess: () =>
			showSuccessNotification(t("store:fiscalStore.minimumConsumptionFiscalSuccess")),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public setRechargeFiscalConfigForPlace = new fetchModel<
		{ placeId: string; config?: RechargeFiscalConfigPayload | null },
		PlaceRechargeFiscalConfig
	>({
		fnPromise: args => enterprise.setRechargeFiscalConfigForPlace(args),
		onSuccess: () =>
			showSuccessNotification(t("store:fiscalStore.rechargeFiscalSuccess")),
		onError: (err: IError) => showErrorNotification(err.message),
	});

	public getPlacesRechargeFiscalConfigs = new fetchModel<{}, PlaceRechargeFiscalConfig[]>(
		{
			fnPromise: args => enterprise.getPlacesRechargeFiscalConfigsByEmployee(args),
			onError: (err: IError) => showErrorNotification(err.message),
		},
	);
}
