type SummableKeys =
	| "count"
	| "subtotal"
	| "discount"
	| "totalValue"
	| "amount"
	| "tip"
	| "value";

type HasSummableKeys = {
	[K in SummableKeys]?: number | null;
};

export function sumField(items: HasSummableKeys[], field: SummableKeys): number {
	return items.reduce((acc, item) => acc + (item[field] || 0), 0);
}
