import React from "react";

export type TablePopoverColumn<T = any> = {
	key: keyof T;
	title: string;
	render?: (value: T[keyof T], record: T) => React.ReactNode;
	align?: "left" | "center" | "right";
};

type TablePopoverContentProps<T> = {
	columns: TablePopoverColumn<T>[];
	dataSource: T[];
	minWidth?: number;
};

export function TablePopoverContent<T>({
	columns,
	dataSource,
	minWidth,
}: TablePopoverContentProps<T>) {
	return (
		<table style={{ minWidth }}>
			<thead>
				<tr>
					{columns.map((column, columnIndex) => (
						<th
							align={column.align}
							key={`column-${String(column.key)}-${columnIndex + 1}`}
						>
							{column.title}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{dataSource.map((record, rowIndex) => (
					<tr key={rowIndex}>
						{columns.map((column, columnIndex) => (
							<td
								key={`row-${rowIndex}-${String(column.key)}-${columnIndex + 1}`}
								align={column.align}
							>
								{column.render
									? column.render(record[column.key], record)
									: record[column.key]}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}
