import { PurchaseInvoiceTranslations } from "./purchase-invoice-pt-br";

export const PURCHASE_INVOICE_EN_US: PurchaseInvoiceTranslations = {
	importationInvoices: {
		cards: {
			importXml: {
				title: "Import via XML",
				description: "Import your fiscal invoices via XML files",
				button: "Click to import",
			},
			importManual: {
				title: "Manual entry",
				description: "Enter your fiscal invoices manually",
				button: "Click to enter",
			},
			importSefaz: {
				title: "Import via Sefaz",
				description: "Import your fiscal invoices directly from Sefaz",
				button: "Click to import",
			},
		},
		importXml: {
			input: {
				title: "Select the XML file to start the import",
				description: "You can click on “Select file” or drag and drop a file",
				button: "Select file",
			},
			labels: {
				storage: "Storage",
				selectStorage: "Select a storage",
				xmlInvoice: "Invoice XML",
				changeXml: "Change XML",
				back: "Back",
				import: "Import",
			},
			selectedFile: "The selected file is: ",
			xmlRequiredMessage: "The XML file is required.",
			storageIdRequiredMessage: "Select a storage.",
			uploadErrorMessage: "Could not read the file.",
		},
	},
};
