export const productCard = {
	infoProduct: `Produto "{{nameProduct}}" pulado`,
	restoreproduct: "Clique aqui para restaurar.",
	code: "SKU:",
	quantities: "Qtd:",
	unit: "Un:",
	unitPrice: "Pago por unidade:",
	unitPriceTooltip: "Valor por unidade em nota fiscal",
	equivalentProduct: "Produto equivalente",
	productNotFound: "Produto não encontrado",
	wishToCreate: "Deseja criá-lo?",
	equivalentUnit: "1 {{product}} equivale a",
	costPricePerUnit: "Custo unitário",
	costPricePerUnitTooltip: "Valor por unidade no sistema Zig",
	costTypeOptions: {
		total: "no total",
		unit: "por unidade",
		milliliter: "por mililitro",
		gram: "por grama",
		kilogram: "por kilograma",
		liter: "por litro",
	},
	chartAccount: "Plano de Contas",
	chartAccountPlaceholder: "Selecione um plano de contas",
};
