exports = module.exports = require("../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-select-transaction-source--select-transaction-source-transactionSourceSelect{width:100%}", ""]);

// exports
exports.locals = {
	"transactionSourceSelect": "src-components-select-transaction-source--select-transaction-source-transactionSourceSelect"
};