import { general } from "./general";
import { productsByTable } from "./products-by-table";
import { totalByClient } from "./total-by-client";
import { totalProductsSold } from "./total-products-sold";
import { tableProductsSold } from "./table-products-sold";
import { performanceTableReport } from "#i18n/translations/pt-BR/event/table-reports/performance-tables";

export const tableReports = {
	tabs: {
		general,
		totalProductsSold,
		totalByClient,
		productsByTable,
		tableProductsSold,
		performanceTableReport,
	},
};
