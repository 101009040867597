type Sorter<T> = (a: T, b: T) => number;
type Falsy = null | undefined;

export const falsySorter: Sorter<Falsy | any> = (a, b) => {
	if (!a && !b) {
		return 0;
	} else if (!a) {
		return -Infinity;
	} else if (!b) {
		return Infinity;
	} else {
		return NaN;
	}
};

export const stringSorter: Sorter<string | Falsy> = (a, b) => {
	if (typeof a !== "string" || typeof b !== "string") {
		return falsySorter(a, b);
	}

	return a.localeCompare(b);
};

export const numberSorter: Sorter<number | Falsy> = (a, b) => {
	if (typeof a !== "number" || typeof b !== "number") {
		return falsySorter(a, b);
	}

	return a - b;
};

export const dateSorter: Sorter<Date | Falsy> = (a, b) => {
	if (!(a instanceof Date) || !(b instanceof Date)) {
		return falsySorter(a, b);
	}

	return a.valueOf() - b.valueOf();
};
