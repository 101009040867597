import { GridTemplateAreas } from "./types";

export const getFormattedGridTemplateAreasAndRows = (
	areas: GridTemplateAreas,
	calcGridColumns?: boolean,
) => {
	return {
		gridAreas: areas.map(row => `"${row.join(" ")}"`).join(" "),
		gridRows: `repeat(${areas.length}, auto)`,
		gridColumns:
			calcGridColumns && areas[0].length ? `repeat(${areas[0].length}, 1fr)` : undefined,
	};
};
