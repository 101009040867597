import React, { ReactNode, useMemo } from "react";
import classNames from "classnames";
import { Button, Tooltip } from "#components/primitive";
import { BREAKING_POINT_QUERIES, BreakingPoints } from "#components/grid-structure";
import Icon from "#components/icon";
import { useMediaQuery } from "usehooks-ts";

import s from "./data-card-summary.module.scss";

export type DataCardSummaryColor =
	| "primary"
	| "secondary"
	| "highlight"
	| "success"
	| "error"
	| "warning"
	| "neutral";

export type DataCardSummaryOrientation = "vertical" | "horizontal";

export type BreakingPointsOrientation = {
	[key in BreakingPoints]?: DataCardSummaryOrientation;
};

export type DataCardSummaryProps = {
	icon: ReactNode;
	value: string | number;
	label: string | number;
	color: DataCardSummaryColor;
	bgColor?: DataCardSummaryColor;

	orientation?: DataCardSummaryOrientation;
	breakingPointsOrientation?: BreakingPointsOrientation;
	className?: string;
	fullWidth?: boolean;
	fullHeight?: boolean;
	tooltip?: string;
	action?: {
		label: string;
		onClick: () => void;
	};
};

export const DataCardSummary = ({
	color,
	className,
	orientation: _orientation = "horizontal",
	icon,
	fullWidth,
	fullHeight,
	tooltip,
	action,
	label,
	value,
	breakingPointsOrientation,
	bgColor,
}: DataCardSummaryProps) => {
	const smBp = useMediaQuery(BREAKING_POINT_QUERIES.sm);
	const mdBp = useMediaQuery(BREAKING_POINT_QUERIES.md);
	const lgBp = useMediaQuery(BREAKING_POINT_QUERIES.lg);
	const xlBp = useMediaQuery(BREAKING_POINT_QUERIES.xl);
	const xlBp2 = useMediaQuery(BREAKING_POINT_QUERIES["2xl"]);
	const xlBp3 = useMediaQuery(BREAKING_POINT_QUERIES["3xl"]);

	const orientation = useMemo(() => {
		if (xlBp3 && breakingPointsOrientation?.["3xl"]) {
			return breakingPointsOrientation?.["3xl"];
		}

		if (xlBp2 && breakingPointsOrientation?.["2xl"]) {
			return breakingPointsOrientation?.["2xl"];
		}

		if (xlBp && breakingPointsOrientation?.xl) {
			return breakingPointsOrientation?.xl;
		}

		if (lgBp && breakingPointsOrientation?.lg) {
			return breakingPointsOrientation?.lg;
		}

		if (mdBp && breakingPointsOrientation?.md) {
			return breakingPointsOrientation?.md;
		}

		if (smBp && breakingPointsOrientation?.sm) {
			return breakingPointsOrientation?.sm;
		}

		return _orientation;
	}, [smBp, mdBp, lgBp, xlBp, xlBp2, xlBp3, breakingPointsOrientation, _orientation]);

	return (
		<div
			className={classNames(
				s.container,
				{
					[s[orientation]]: orientation,
					[s[color]]: color,
					[s[`bg--${bgColor}`]]: bgColor,
					[s.fullWidth]: fullWidth,
					[s.fullHeight]: fullHeight,
				},
				className,
			)}
			aria-label={`${label}: ${value}`}
		>
			<div className={s.iconWrapper}>
				<div className={s.iconContent}>{icon}</div>
			</div>
			<div className={s.content}>
				<b>
					{value}

					{Boolean(tooltip) && (
						<Tooltip content={tooltip}>
							<Icon icon="info-outlined" width={12} height={12} viewBox="0 0 20 20" />
						</Tooltip>
					)}
				</b>

				<label>{label}</label>

				{action && (
					<Button className={s.actionButton} variant="text" onClick={action.onClick}>
						{action.label}
					</Button>
				)}
			</div>
		</div>
	);
};
