export const satisfactionSurvey = {
	title: "Satisfaction Survey",
	periodData: {
		thirty: "Last 30 days",
		sixty: "Last 60 days",
		ninety: "Last 90 days",
		rangeFormat: "{{sinceDay}} of {{sinceMonth}} to {{untilDay}} of {{untilMonth}}",
		period: "Period",
		custom: "Custom period",
		placeholder: "Select period",
	},
	tabs: {
		results: "Results",
		shareSettings: "Settings and Sharing",
		searchSettings: "Survey Settings",
		newPlaceAvailable: "New location available",
		smsReport: "Investment Report",
	},
	formatQuestionType: {
		service: "Service / Customer service",
		product: "Product",
		mixes: "Menu Variety",
		cleaness: "Cleaning",
		price: "Price",
		music: "Music",
		nps: "NPS",
	},
	results: {
		columns: {
			birthDate: "Birthdate",
			client: "Client",
			date: "Date",
			document: "Document",
			averageTicket: "Average Ticket",
			phone: "Phone",
			ratingDate: "Rating Date",
			comment: "Comment",
		},
		fileTitle: "Event Rating History",
		filename: "nps-reviews",
		customerAnswer_one: "{{totCustomers}} customer responded to satisfaction survey",
		customerAnswer_other:
			"{{totCustomers}} customers responded to the satisfaction survey",
		lastDays_one: "on the last {{days}} day",
		lastDays_other: "in the last {{days}} days",
		filterPlaceholder: "Filter by place",
		searchClientPlaceholder: "Search by name or document number",
		export: "Export to Excel",
		resultsEvent: "Search result by event",
		emptyStateTitle: "Place not enabled",
		emptyStateDescription:
			"This place is not enabled to receive satisfaction survey responses. Contact the Zig team so that you can activate this service.",
		popover: {
			answer_one: "{{totalAnswers}} rating",
			answer_other: "{{totalAnswers}} ratings",
			client_one: "{{count}} customer",
			client_other: "{{count}} customers",
			opinion_one: "{{count}} review",
			opinion_other: "{{count}} reviews",
		},
		drawer: {
			title: "Experience Details",
			event: "Event",
			date: "Date",
			consumedProducts: "Consumed Products",
			assessment: "Assessment",
			stars_one: "{{value}} star",
			stars_other: "{{value}} stars",
			answerNps:
				"From 1 to 10, the chance of this customer recommending {{name}} to friends and family is",
			client: "Client",
			cpf: "CPF",
			phone: "Phone",
			birthDate: "Date of birth",
		},
	},
	shareSettings: {
		title: "Sharing settings",
		content:
			"It is very important that the means of customer activation are defined so that you can get fair feedback from your organization.",
		qrCodeSection: {
			title: "QR Code on table / lounge",
			description:
				"Click the button below to download and print the QR Code that will take your customer to the satisfaction survey link.",
			downloadButton: "Download QR Code",
		},
		smsSection: {
			title: "Manual SMS",
			description:
				"Copy the link below and send a message to the list of clients selected by you. Just go to the messages section in the client area.",
			copyButton: "Copy survey link",
			cancelUpdates: "Cancel updates",
			saveUpdates: "Save updates",
			dailyLimit: {
				label: "Daily SMS limit",
				tooltip: "The charge per SMS sent is stated in your contract.",
				description: "This will be the limit for automatic link sending.",
			},
		},
		modal: {
			qrCode: "Download QRCode",
			qrCodeDescription: "Click here to open QRCode",
			link: "Copy link",
			linkDescription: "Click to copy survey link",
			downloadAllQRCode: "Download all QRCodes",
			rowModal: {
				qrCode:
					'There are no places enabled. To download the QRCode of this survey, just activate it in the "Survey Settings" tab',
				link:
					'There are no places enabled. To generate a link to a satisfaction survey, simply activate it in the "Survey Settings" tab',
			},
		},
	},
	searchSettings: {
		emptyState: {
			title: "No place enabled",
			description:
				"No Place enabled to configure satisfaction survey found. Contact the Zig team so that you can activate this service.",
		},
		title: "Survey settings",
		description: "Choose which parameters you want your customers to evaluate at home.",
		cancelButton: "Cancel changes",
		saveButton: "Save changes",
		new: "New",
		successNotification: "Search settings have been updated",
		shouldSendSmsOnCheckout: "Automatically send NPS SMS to all customers after checkout",
	},
	customModal: {
		successNotification: "Survey link copied",
	},
	smsReport: {
		filterByPlace: "Filter by place",
		filterByDate: "Event Date",
		columns: {
			date: "Event Date",
			smsSentCount: "Sms sent",
			npsAnswersCount: "Responses",
			conversion: "SMS Conversion Rate",
		},
		totalizers: {
			smsSents: "Sms sent",
			npsFormAnswers: "Total responses",
			checkins: "Total conversion rate",
		},
	},
};
