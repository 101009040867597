import { ProductSoldMountables } from "#resources/api/enterprise-generated";
import { Modal, Table } from "antd";
import React from "react";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";

import s from "./mountable-products-modal.module.scss";

type MountableProductsModalProps = {
	open: boolean;
	onClose: () => void;
	products: ProductSoldMountables[];
	productName: string;
};

export const MountableProductsModal = ({
	onClose,
	open,
	products,
	productName,
}: MountableProductsModalProps) => {
	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport.totalSales.mountableProductsTable",
	});

	const columns: ColumnsType<ProductSoldMountables> = [
		{
			key: "name",
			dataIndex: "name",
			title: t("name"),
			align: "left",
		},
		{
			key: "section",
			dataIndex: "section",
			title: t("section"),
			align: "left",
		},
		{
			key: "count",
			dataIndex: "count",
			title: t("count"),
			align: "right",
		},
	];

	return (
		<Modal
			className={s.modal}
			title={<strong>{productName}</strong>}
			width={640}
			onCancel={onClose}
			open={open}
			footer={null}
		>
			<Table
				className={s.table}
				pagination={false}
				dataSource={products}
				columns={columns}
			/>
		</Modal>
	);
};
