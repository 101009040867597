import { ImportTranslations } from "./import-pt-br";

/* eslint-disable max-len */
export const IMPORT_EN_US: ImportTranslations = {
	title: "XML Import",
	invoiceData: {
		title: "Invoice Data",
		billNumber: "Invoice Number",
		noteSeries: "Invoice Series",
		natureOfOperation: "Nature of Operation",
		accesskey: "Access Key",
		issuer: "Issuer",
		CNPJAndCPF: "CNPJ/CPF",
		noteValue: "Invoice Value",
		discount: "Discount",
		totalValue: "Total Value",
		dateInputLabel: "Date of Entry in Physical Stock:",
	},
	duplicates: {
		title: "Duplicates",
		duplicate: "Duplicate",
		dueDate: "Due Date",
		value: "Value",
	},
	payment: {
		title: "Payment",
		cashPayment: "Cash Payment",
		installmentsPayment: "Installments",
		payment: "Payment",
	},
	ShippingAndExpenses: {
		title: "Shipping and Expenses",
		shipping: "Shipping",
		insurance: "Insurance",
		additionalExpenses: "Additional Expenses",
	},
	infoCalculator:
		"The basis for calculating the unit cost is: Total Value - Discount + IPI + ICMS + Shipping + Insurance + Other expenses / Quantity",
	product: {
		amount: "Quantity",
		commercialUnit: "Unit of Measurement",
		unitValue: "Price per Unit",
	},
	invoiceDetailsFooterButtons: {
		cancel: "Cancel",
		next: "Next",
	},
	newProductModal: {
		triggerText: "Click here to register it.",
		modalTitle: "Add item",
		contentText: "What are you registering?",
		insumo: {
			title: "Ingredient",
			description:
				"Can be used as ingredients in recipes for other products and do not appear in your points of sale.",
			action: "Click to create new ingredient",
		},
		produto: {
			title: "Product",
			description:
				"Final sale products that may or may not use ingredients in their production.",
			action: "Click to create new product",
		},
	},
};
