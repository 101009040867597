import React, { useState } from "react";
import { useSalesReport } from "../../context";
import {
	useProductsSoldForEventOverview,
	useProductsSoldOverview,
	useProductsSoldReport,
	useProductsSoldReportForEvent,
} from "#resources/hooks/integrations/products/query";
import { TableProductsSold } from "../table-products-sold/table-products-sold";
import { TOTAL_PER_PAGE } from "../../context/constants";
import {
	PaginationResponse,
	ProductsSoldOrderBy,
	ProductsSoldOrderByOrder,
	ProductsSoldReportResponse,
} from "#resources/api/enterprise-generated";
import { TableTotalPagination } from "#components/table-components/table-total-pagination";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import { useCurrency, useDisclosure } from "#resources/hooks";
import { TagLabel } from "#components/primitive";
import { MountableProductsModal } from "../mountable-products-modal";
import { SorterResult } from "antd/lib/table/interface";

import s from "./table-products-sold-no-group.module.scss";

export const TableProductsSoldNoGroup = () => {
	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport.totalSales.tableProductsSold",
	});
	const { t: tGK } = useTranslation("globalKeys");
	const {
		selectedTab,
		groupBy,
		placeId,
		since,
		until,
		eventLevel,
		eventId,
		productsFilter,
		transactionsFilter,
		categoriesFilter,
	} = useSalesReport();

	const [orderBy, setOrderBy] = useState<ProductsSoldOrderBy | null>({
		column: "productName",
		order: "asc",
	});

	const { formatCurrency } = useCurrency();

	const [
		selectedProduct,
		setSelectedProduct,
	] = useState<ProductsSoldReportResponse | null>(null);

	const { isOpen, onClose, onOpen } = useDisclosure({
		onCloseCallback: () => {
			setSelectedProduct(null);
		},
	});

	const handleOpenMountableProductsModal = (product: ProductsSoldReportResponse) => {
		setSelectedProduct(product);
		onOpen();
	};

	const [pagination, setPagination] = useState<PaginationResponse>({
		currentPage: 1,
		perPage: TOTAL_PER_PAGE,
		lastPage: 1,
		total: 0,
	});

	const productIds = productsFilter.length ? productsFilter : null;
	const sources = transactionsFilter.length ? transactionsFilter : null;
	const categoryIds = categoriesFilter.length ? categoriesFilter : null;

	const enabledPlaceRequests = Boolean(
		selectedTab === "total-sales" && groupBy === "no-group" && !eventLevel,
	);
	const enabledEventRequests = Boolean(
		selectedTab === "total-sales" && groupBy === "no-group" && eventLevel,
	);

	const {
		data: productsSoldReportData,
		isFetching: productsSoldReportIsFetching,
	} = useProductsSoldReport(
		{
			placeId,
			since,
			until,
			filters: {
				productIds,
				categoryIds,
				sources,
			},
			pagination,
			orderBy,
		},
		{
			enabled: enabledPlaceRequests,
			retry: false,
			placeholderData: data => data,
		},
	);

	const {
		data: productsSoldReportForEventData,
		isFetching: productsSoldReportForEventIsFetching,
	} = useProductsSoldReportForEvent(
		{
			eventId: eventId || "",
			filters: {
				productIds,
				categoryIds,
				sources,
			},
			pagination,
			orderBy,
		},
		{
			enabled: enabledEventRequests,
			retry: false,
			placeholderData: data => data,
		},
	);

	const {
		data: productsSoldOverviewData,
		isFetching: productsSoldOverviewIsFetching,
	} = useProductsSoldOverview(
		{
			since,
			until,
			placeId,
			filters: {
				categoryIds,
				productIds,
				sources,
			},
		},
		{
			enabled: enabledPlaceRequests,
			retry: false,
			placeholderData: data => data,
		},
	);

	const {
		data: productsSoldForEventOverviewData,
		isFetching: productsSoldForEventOverviewIsFetching,
	} = useProductsSoldForEventOverview(
		{
			eventId: eventId || "",
			filters: {
				categoryIds,
				productIds,
				sources,
			},
		},
		{
			enabled: enabledEventRequests,
			retry: false,
			placeholderData: data => data,
		},
	);

	const overviewData = eventLevel
		? productsSoldForEventOverviewData
		: productsSoldOverviewData;
	const overviewIsLoading = eventLevel
		? productsSoldForEventOverviewIsFetching
		: productsSoldOverviewIsFetching;

	const data = eventLevel ? productsSoldReportForEventData : productsSoldReportData;
	const productsListIsLoading = eventLevel
		? productsSoldReportForEventIsFetching
		: productsSoldReportIsFetching;

	const isLoading = productsListIsLoading || overviewIsLoading;

	const totalizers = {
		countAmount: overviewData?.count || 0,
		discountAmount: overviewData?.discount || 0,
		subtotalAmount: overviewData?.subtotal || 0,
		totalAmount: overviewData?.totalValue || 0,
	};

	React.useEffect(() => {
		if (!data?.pagination) return;

		setPagination(prev => ({
			...prev,
			total: data.pagination.total,
			perPage: data.pagination.perPage,
			lastPage: data.pagination.lastPage,
		}));
	}, [data?.pagination]);

	const columns: ColumnsType<ProductsSoldReportResponse> = [
		{
			key: "fiscalCode",
			dataIndex: "fiscalCode",
			title: t("sku"),
		},
		{
			key: "image",
			dataIndex: "image",
			title: "",
			width: 80,
			render: (value: ProductsSoldReportResponse["image"]) => {
				if (!value?.url) {
					return <div className={s.productImage} />;
				}

				return (
					<img src={value.url} alt={t("productImageAlt")} className={s.productImage} />
				);
			},
		},
		{
			key: "productName",
			dataIndex: "productName",
			title: t("name"),
			sorter: true,
			defaultSortOrder: orderBy?.order === "asc" ? "ascend" : "descend",
			render: (name, product) => {
				return (
					<>
						{name}

						{product.mountableProducts && (
							<u role="button" onClick={() => handleOpenMountableProductsModal(product)}>
								({t("mountable")})
							</u>
						)}
					</>
				);
			},
		},
		{
			key: "count",
			dataIndex: "count",
			title: t("unitQuantity"),
			align: "right",
		},
		{
			key: "unitValue",
			dataIndex: "unitValue",
			title: t("unitValue"),
			align: "right",
			render: (value: number, r) =>
				r.productValues && r.productValues?.length > 1 ? (
					<TagLabel type="info">
						{t("values", {
							length: r.productValues?.length,
						})}{" "}
					</TagLabel>
				) : (
					formatCurrency(value)
				),
		},
		{
			key: "subtotal",
			dataIndex: "subtotal",
			title: t("subtotal"),
			align: "right",
			render: value => formatCurrency(value),
		},
		{
			key: "discount",
			dataIndex: "discount",
			title: t("discounts"),
			align: "right",
			render: value => formatCurrency(value),
		},
		{
			key: "totalValue",
			dataIndex: "totalValue",
			title: t("totalValue"),
			align: "right",
			render: value => formatCurrency(value),
		},
	];

	const handleTableChange = (
		_sorter:
			| SorterResult<ProductsSoldReportResponse>
			| SorterResult<ProductsSoldReportResponse>[],
	) => {
		const sorter = Array.isArray(_sorter) ? _sorter[0] : _sorter;

		if (!sorter?.columnKey || !sorter.order) {
			setOrderBy(null);
			return;
		}

		const { order } = sorter;

		const sortOrderApi: ProductsSoldOrderByOrder = order === "ascend" ? "asc" : "desc";

		setOrderBy({
			column: "productName",
			order: sortOrderApi,
		});
	};

	const dataSource = data?.productList || [];

	return (
		<>
			<MountableProductsModal
				open={isOpen}
				onClose={onClose}
				products={selectedProduct?.mountableProducts ?? []}
				productName={selectedProduct?.productName ?? ""}
			/>

			<TableProductsSold
				dataSource={dataSource}
				TableProps={{
					loading: isLoading,
					columns,
					onChange: (_pagination, _filters, sorter) => handleTableChange(sorter),
					pagination: {
						current: pagination.currentPage,
						total: pagination.total,
						pageSize: pagination.perPage,
						showTotal: (total, range) => (
							<TableTotalPagination total={total} range={range} />
						),
						showSizeChanger: true,
						onChange: (pageNumber, pageSize) =>
							setPagination(prev => ({
								...prev,
								currentPage: pageNumber,
								perPage: pageSize,
							})),
					},
				}}
				totalizers={{
					...totalizers,
					totalDescription: tGK("total"),
				}}
			/>
		</>
	);
};
