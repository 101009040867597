import classNames from "classnames";
import * as React from "react";
import s from "./card.scss";

export interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
	className?: string;
	children?: React.ReactNode;
}

export function Card({ className, children, ...props }: ICardProps) {
	return (
		<div className={classNames(s.container, className)} {...props}>
			{children}
		</div>
	);
}
